import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, reactive, getCurrentInstance} from 'vue'


export default {
  setup(__props) {


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("h1", null, "页面"))
}
}

}